import React from 'react';
import './App.css';
import { motion } from 'framer-motion';
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-23372761-7');
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  // animacoes
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className='main-container'>
      <div className='wrapper clearfix'>
        <motion.div
          initial='hidden'
          animate='visible'
          transition={{ duration: 0.5 }}
          variants={variants}
        >
          <p className='title'>INICIATIVA</p>
          <p className='logo-wrapper'>
            <picture>
              <source srcset='/img/logo-128.webp' type='image/webp' />
              <img src='/img/logo-128.png' alt='Logo Iniciativa Eleve' width='50%' />
            </picture>
          </p>
        </motion.div>
        <motion.div
          initial='hidden'
          animate='visible'
          transition={{ duration: 1.25 }}
          variants={variants}
        >
          <p className='mission'>
            Somos um coletivo de entusiastas da educação técnica. Estamos juntos para ajudar jovens
            cariocas no acesso às escolas públicas profissionalizantes por meio de reforço escolar.
          </p>
        </motion.div>
        <motion.div
          initial='hidden'
          animate='visible'
          transition={{ duration: 2 }}
          variants={variants}
        >
          <p className='contact'>
            Entre em contato com a gente por e-mail
            <br />
            <strong>iniciativaeleve@gmail.com</strong> e visite nossas redes sociais.
          </p>
          <a href='https://facebook.com/iniciativaeleve' target='_new' className='social-icon'>
            <picture>
              <source srcset='/img/icon-fb.webp' type='image/webp' />
              <img src='/img/icon-fb.png' alt='Facebook @iniciativaeleve' />
            </picture>
          </a>
          <a href='https://instagram.com/iniciativaeleve' target='_new' className='social-icon'>
            <picture>
              <source srcset='/img/icon-ig.webp' type='image/webp' />
              <img src='/img/icon-ig.png' alt='Instagram @iniciativaeleve' />
            </picture>
          </a>
        </motion.div>
      </div>
    </div>
  );
}

export default App;
